import { Klass } from '#agents/lib/transforms'
import Teachership from './Teachership'
import Clazz from './Clazz'

class ClazzStudentsCounts {
  actived: number
  total: number
}

export class ClazzHomeworkPapersStat {
  subject_id: number
  published_on: string
  finished_count: number
  unfinished_count: number
}

export default class ClazzInfo extends Clazz {
  @Klass(Teachership)
  available_teacherships: Teachership[]

  @Klass(ClazzStudentsCounts)
  students_counts: ClazzStudentsCounts

  @Klass(ClazzHomeworkPapersStat)
  homework_papers_stats: ClazzHomeworkPapersStat[]
}
