import { Klass } from '#agents/lib/transforms'
import Big from "big.js"
import { Decimal } from "#agents/lib/transforms"
import { Attachment } from "./Attachment"

export class AnswerInput {
  id: number
  question_input_id: number
  state:  "unfilled" | "draft" | "processing" | "checking" | "correct" | "wrong" | "scored"
  @Decimal() score: Big
  answer_text: string
  @Klass(Attachment) answer_audio_info?: Attachment
}


export default class AnswerSheetInfo {
  id: number

  @Klass(AnswerInput) answer_inputs: AnswerInput[]
}
