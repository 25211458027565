import { Klass } from '#agents/lib/transforms'

export default class Product {
  id: number
  name:string
  notification_description: string
  ticket_codes: string

  @Klass(Date)
  updated_at: Date
}
