import { Klass } from '#agents/lib/transforms'
import { Site } from './Site'
import Province from './Province'

export class Territory {
  id!: number
  name!: string

  @Klass(Site) site!: Site
  @Klass(Province) province!: Province
}
