import { Klass } from '#agents/lib/transforms'
import Subject from './Subject'

export default class Teacher {
  id: number
  name: string
  usercode: string

  @Klass(Subject) default_subject: Subject
}
