import { Klass } from '#agents/lib/transforms'
import Homework from "./Homework"
import Workathon from "./Workathon"

export default class HomeworkInfo extends Homework {
  school_name: string
  school_id: number
  clazz_name: string
  clazz_id: number
  textbook_name: string
  edition_name: string
  volume_name: string
  allow_published_score: ("workathoner" | "clazz")[] = []
  finished_homework_papers_count: number

  @Klass(Workathon)
  workathon!: Workathon
}
