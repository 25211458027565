import { Klass } from '#agents/lib/transforms'
import School from "./School"

class SchoolStudentsCounts {
  actived: number
  total: number
}

export class SchoolHomeworkPapersStat {
  subject_id: number
  published_on: string
  finished_count: number
  unfinished_count: number
}

export default class SchoolInfo extends School {
  location_fullnames: string[]

  @Klass(SchoolStudentsCounts)
  students_counts: SchoolStudentsCounts

  @Klass(SchoolHomeworkPapersStat)
  homework_papers_stats: SchoolHomeworkPapersStat[]
}
