import { Klass } from '#agents/lib/transforms'
import Unit from "./Unit"

export default class UnitModule {
  id: number
  name: string

  @Klass(Unit)
  units: Unit[]
}
