import { Klass } from '#agents/lib/transforms'
import { Territory } from './Territory'

export class Event {
  id!: number
  name: string
  one_time_only: boolean
  state: string
  state_text: string
  site_id: number
  wechat_url: string

  @Klass(Territory) territory: Territory

  @Klass(Date) updated_at: Date
  @Klass(Date) created_at: Date
}
